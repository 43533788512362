<template>
  <v-dialog
    @click:outside="dialog = false"
    v-model="dialog"
    max-width="550"
    transition="dialog-bottom-transition"
  >
    <v-container class="background rounded-lg">
      <v-row cols="12" sm="6" md="4" justify="end">
        <v-icon
          class="ma-0 pa-0 mt-2 mr-2"
          color="primary"
          @click="dialog = false"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row class="align-center ma-0 pa-0 my-2" justify="center">
        <v-img
          alt="ApoTalent"
          class="shrink mr-2"
          src="@/assets/apotalent/Isotipo black.svg"
          transition="scale-transition"
          width="95"
        />
      </v-row>
      <v-row class="secondary--text mx-4 mb-8"
        >{{ keyValues["payed_" + service] }}
      </v-row>
    </v-container>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    service: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters({ keyValues: "core/keyValues" }),
  },
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
.customButton {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}
</style>
