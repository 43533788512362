<template>
  <v-row class="pa-0 ma-0 mt-2" justify="center">
    <v-col cols="10" sm="8" md="4" class="pa-0">
      <v-card
        class="transition-fast-in-fast-out pa-0 ma-0 arrow_box"
        style="background: white;"
        justify-sm="center"
      >
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="1" class="pa-0 pt-3 text-center">
            <v-icon color="secondary" size="20" class="mt-n2 ml-2"
              >mdi-information</v-icon
            ></v-col
          >
          <v-col cols="11" class="pa-0 d-flex">
            <h6 class="my-2 pl-2 text-caption primary--text">
              <span v-html="$t('offer.pharmacy_offer_explain')"></span>
            </h6>
            <div class="ml-auto mr-0">
              <v-icon @click="closeCard" class="mr-1 cursor-pointer" size="14"
                >mdi-close</v-icon
              >
            </div>
          </v-col>
          <v-col class="d-flex justify-center">
            <a
              class="secondary--text font-weight-medium text-caption"
              style="text-decoration: underline"
              @click="goToFaqs"
              >{{ $t("offer.faqs_redirect_card") }}
            </a>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions({
      dontShowOfferExplainAgain: "user/dontShowOfferExplainAgain"
    }),
    closeCard() {
      this.dontShowOfferExplainAgain();
    },
    goToFaqs() {
      let routeData = this.$router.resolve({ name: "faqs" });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>
<style lang="scss">
.arrow_box {
  position: relative;
  border: 3px solid #c2e1f5;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}
.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 15%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: white;
  border-width: 20px;
  margin-left: -20px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  //border-bottom-color: var(--v-primary-base) !important;
  border-width: 24px;
  margin-left: -24px;
}
</style>
