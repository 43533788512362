<template>
  <v-container class="ma-0 pa-0">
    <v-card
      color="white"
      class="rounded-lg mb-3"
      :to="{
        name: 'pharmacy-data',
        params: {
          pharmacy_id: pharmacy.id
        }
      }"
      v-for="(pharmacy, index) of pharmacies"
      :key="index"
    >
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="12" md="12" class="ma-0 pa-0 pl-4 mt-2"
          ><v-card-title
            class="ml-1 ma-0 pa-0 primary--text text-body-1 font-weight-medium"
            >{{ pharmacy.commercial_name }}</v-card-title
          ></v-col
        >
        <!-- <v-col cols="4" md="2" sm="2" class="ma-0 pa-0 mt-1 text-right"
          ><v-btn icon class="justify-end">
            <v-icon class="mr-2">mdi-dots-vertical</v-icon>
          </v-btn></v-col
        > -->
      </v-row>
      <v-row class="secondary--text ma-0 pa-0">
        <v-col cols="6" class="mt-1 text-caption pa-0 text-left pl-4"
          ><v-icon size="17" color="secondary" class="mx-1"
            >mdi-clock-time-five-outline</v-icon
          >{{ pharmacy.open_hour.name }}</v-col
        >
        <v-col cols="6" class="mt-1 text-caption text-left pa-0">
          <v-icon size="17" color="secondary" class="mr-1"
            >mdi-map-marker</v-icon
          >{{ pharmacy | pharmacyAddress }}</v-col
        >
      </v-row>
      <v-divider class="mx-8 mt-3"></v-divider>
      <v-card-actions>
        <v-icon color="secondary" class="mr-1 ml-2">mdi-briefcase </v-icon>
        <span class="text-caption secondary--text ml-1 pb-1 pl-1">{{
          $t("expert.offers", { number: pharmacy.expert_offers })
        }}</span>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import Mixins from "@/mixins/index.js";
import { mapGetters } from "vuex";
export default {
  mixins: [Mixins],
  data() {
    return {
      message: " "
    };
  },
  computed: {
    ...mapGetters({ offers: "offer/offers" }),
    ...mapGetters({ pharmacies: "expert/expertPharmacies" })
  },
  mounted() {},
  methods: {}
};
</script>
<style scoped></style>
