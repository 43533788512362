export default [
    {
        pharmacy: [
            "tabs.active",
            "tabs.closed",
            "tabs.draft",
        ],
        candidate: [
            "tabs.news",
            "tabs.favs",
            "tabs.discard",
        ],
        expert: [
            "tabs.pharmacy",
            "tabs.contracted_offers",
        ]
    }]