<template>
  <v-container class="ma-0 pa-0">
    <v-card
      color="white"
      class="rounded-lg mb-2"
      :to="{
        name: 'candidate-detail',
        params: {
          offer_id: offer.id,
        },
      }"
      v-for="(offer, index) of offers"
      :key="index"
    >
      <v-row class="ma-0 pa-0">
        <v-col cols="8" md="10" sm="10" class="ma-0 pa-0 pl-4 mt-2"
          ><v-card-title v-if="offer"
            class="ma-0 pa-0 primary--text text-body-1 font-weight-medium"
            >{{ offer.position.name }}</v-card-title
          ></v-col
        >
        <v-col cols="4" md="2" sm="2" class="pa-0 text-caption mt-2 text-center"
          ><v-chip small :color="getColor(offer.candidate_status)" text-color="white">
            {{ getStatusText(offer.candidate_status) }}
          </v-chip></v-col
        >
      </v-row>
      <v-card-subtitle class="secondary--text ma-0 pa-0 text-subtitle-2 pl-5"
        >{{ offer | formatAddress }}
      </v-card-subtitle>
      <v-row class="secondary--text ma-0 pa-0">
        <v-col cols="6" class="mt-1 text-caption pa-0 text-left pl-4"
          ><v-icon size="17" color="secondary" class="mx-1"
            >mdi-clock-time-five-outline</v-icon
          >{{ gettime(offer.created) }}</v-col
        >
        <v-col cols="6" class="mt-1 mb-4 text-caption text-left pa-0">
          <v-icon size="17" color="secondary" class="mr-1">mdi-map-marker</v-icon
          >{{ offer | formatAddress }}</v-col
        >
      </v-row>
    </v-card>
    <!-- <v-card
      color="white"
      class="rounded-lg mb-2"
      :to="{ name: 'candidate-detail' }"
    >
      <v-row class="ma-0 pa-0">
        <v-col cols="8" md="10" sm="10" class="ma-0 pa-0 pl-4 mt-2"
          ><v-card-title
            class="ma-0 pa-0 primary--text text-body-1 font-weight-medium"
            >Técnico de farmacia</v-card-title
          ></v-col
        >
        <v-col cols="4" md="2" sm="2" class="pa-0 text-caption mt-2 text-center"
          ><v-chip small color="secondary" text-color="white">
            {{ $t("operation.favourite") }}
          </v-chip></v-col
        >
      </v-row>
      <v-card-subtitle class="tertiary--text ma-0 pa-0 text-subtitle-2 pl-5"
        >Alcobendas, Madrid</v-card-subtitle
      >
      <v-row class="tertiary--text ma-0 pa-0">
        <v-col cols="6" class="mt-1 text-caption pa-0 text-left pl-4"
          ><v-icon size="17" color="tertiary" class="mx-1"
            >mdi-clock-time-five-outline</v-icon
          >Hace 60 minutos</v-col
        >
        <v-col cols="6" class="mt-1 mb-4 text-caption text-left pa-0">
          <v-icon size="17" color="tertiary" class="mr-1">mdi-map-marker</v-icon
          >Madrid, Madrid</v-col
        >
      </v-row>
    </v-card>
    <v-card
      color="white"
      class="rounded-lg mb-2"
      :to="{ name: 'candidate-detail' }"
    >
      <v-row class="ma-0 pa-0">
        <v-col cols="8" md="10" sm="10" class="ma-0 pa-0 pl-4 mt-2"
          ><v-card-title
            class="ma-0 pa-0 primary--text text-body-1 font-weight-medium"
            >Técnico de farmacia</v-card-title
          ></v-col
        >
        <v-col cols="4" md="2" sm="2" class="pa-0 text-caption mt-2 text-center"
          ><v-chip small color="danger" text-color="white">
            {{ $t("operation.discard") }}
          </v-chip></v-col
        >
      </v-row>
      <v-card-subtitle class="tertiary--text ma-0 pa-0 text-subtitle-2 pl-5"
        >Alcobendas, Madrid</v-card-subtitle
      >
      <v-row class="tertiary--text ma-0 pa-0">
        <v-col cols="6" class="mt-1 text-caption pa-0 text-left pl-4"
          ><v-icon size="17" color="tertiary" class="mx-1"
            >mdi-clock-time-five-outline</v-icon
          >Hace 60 minutos</v-col
        >
        <v-col cols="6" class="mt-1 mb-4 text-caption text-left pa-0">
          <v-icon size="17" color="tertiary" class="mr-1">mdi-map-marker</v-icon
          >Madrid, Madrid</v-col
        >
      </v-row>
    </v-card> -->
  </v-container>
</template>
<script>
import Mixins from "@/mixins/index.js";
import { mapGetters } from "vuex";
export default {
  mixins: [Mixins],
  data() {
    return {
      colors:{"N":"sucess","F":"secondary","D":"danger"}
    };
  },
  computed: {
    ...mapGetters({ offers: "match/offers" }),
    ...mapGetters({ candidateStatusChoices: "core/candidateStatusChoices" }),
  },
  methods: {
    getColor(status){
      return this.colors[status]
    },
    getStatusText(status){
      return this.candidateStatusChoices.find(elm=>elm[0]==status)[1]
    }
  },
};
</script>
<style scoped></style>
