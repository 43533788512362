<template>
  <v-container fluid class="mb-8">
    <layout :back="false" />
    <!-- <welcome /> -->
    <div class="mx-2">
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="8" md="4" class="ma-0">
          <v-text-field
            :label="userType == 'expert' ? $t('expert.field') : $t('offer.my')"
            append-icon="mdi-magnify"
            hide-details
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row
        class="pa-0 ma-0"
        justify-sm="center"
        v-if="userType == 'alquimia' || userType == 'pharmacygroup'"
      >
        <v-col cols="12" sm="8" md="4" class="ma-0">
          <v-select
            :items="pharmacies"
            v-model="pharmacyId"
            :label="$tc('pharmacy.title', pharmacies.length)"
            item-text="commercial_name"
            item-value="id"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="8" md="4">
          <v-tabs
            fixed-tabs
            v-model="tab"
            class="v-slider-group"
            active-class="shadowTabs"
          >
            <v-tabs-slider color="primary--text"></v-tabs-slider>
            <v-tab
              v-for="item in items"
              :key="item"
              class="text-caption ma-0 pa-0 mx-2"
            >
              {{ $t(item) }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row cols="12" class="pa-0 ma-0" justify-sm="center">
        <v-col
          cols="7"
          sm="8"
          md="4"
          class="ma-0 text-subtitle-1"
          v-if="['pharmacy', 'pharmacygroup'].includes(userType) && childId"
        >
          <span @click="redirectNewOffer" style="cursor: pointer">
            + {{ $t("offer.create") }}
          </span>
        </v-col>
      </v-row>
    </div>
    <transition name="fade" mode="out-in">
      <offer-explain-card v-if="showOfferExplainModal()" />
    </transition>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in items" :key="index">
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4">
            <component
              :is="
                userType == 'candidate'
                  ? 'CandidateOffers'
                  : ['pharmacy', 'alquimia', 'pharmacygroup'].includes(
                      userType
                    ) ||
                    (userType == 'expert' && tab == 1)
                  ? 'PharmacyOffers'
                  : userType == 'expert'
                  ? 'Expertsoffers'
                  : ''"/>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <faq-card
      v-if="candidateOffers.length == 0 && tab == 0 && userType === 'candidate'"
      type="offers"
    ></faq-card>
    <initial :type="intialType" v-if="initialModal" />
    <div v-if="payModal">
      <payed :service="serviceType"></payed>
    </div>
  </v-container>
</template>
<script>
// Welcome modal component
// import Welcome from "@/components/modals/Welcome.vue";

// Layout component
import layout from "@/components/layouts/Profile.vue";

// Offers components
import PharmacyOffers from "@/components/offers/PharmacyCards.vue";
import CandidateOffers from "@/components/offers/CandidateCards.vue";
import Expertsoffers from "@/components/offers/ExpertCards.vue";
import tabsOptions from "@/components/offers/TabsOptions.js";
import { mapActions, mapGetters } from "vuex";
import Payed from "../../components/modals/Payed.vue";
import Initial from "../../components/modals/InitialModal.vue";
import FaqCard from "@/components/shared/FaqCard.vue";
import OfferExplainCard from "@/components/shared/OfferExplainCard.vue";
export default {
  components: {
    PharmacyOffers,
    CandidateOffers,
    Expertsoffers,
    layout,
    Payed,
    Initial,
    FaqCard,
    OfferExplainCard
  },
  data() {
    return {
      tab: null,
      tabsOptions: tabsOptions,
      items: [],
      filters: ["Recientes", "Antiguas"],
      combobox: "",
      mounted: false,
      payModal: false,
      initialModal: false,
      intialType: null,
      serviceType: null,
      showCard: true,
      pharmacyId: null
    };
  },
  watch: {
    async pharmacyId() {
      this.updateChildId(this.pharmacyId);
      let getOffers =
        this.userType == "pharmacygroup"
          ? this.getPharmacyGroupOffers
          : this.getAlquimiaOffers;
      switch (this.tab) {
        case 0:
          await getOffers({
            offer_status: "selection",
            pharmacy_id: this.pharmacyId
          });

          break;
        case 1:
          await getOffers({
            offer_status: "closed",
            pharmacy_id: this.pharmacyId
          });
          break;
        case 2:
          await getOffers({
            offer_status: "draft",
            pharmacy_id: this.pharmacyId
          });
          break;
      }
    },
    async tab() {
      this.enableLoading();
      if (this.userType == "candidate") {
        switch (this.tab) {
          case 0:
            await this.getCandidateOffers({
              candidate_status: "N"
            });
            break;
          case 1:
            await this.getCandidateOffers({
              candidate_status: "F"
            });
            break;
          case 2:
            await this.getCandidateOffers({
              candidate_status: "D"
            });
            break;
        }
      } else if (this.userType == "pharmacy") {
        switch (this.tab) {
          case 0:
            await this.getPharmacyOffers({
              offer_status: "selection",
              pharmacy_id: this.childId
            });

            break;
          case 1:
            await this.getPharmacyOffers({
              offer_status: "closed",
              pharmacy_id: this.childId
            });
            break;
          case 2:
            await this.getPharmacyOffers({
              offer_status: "draft",
              pharmacy_id: this.childId
            });
            break;
        }
      } else if (this.userType == "expert") {
        switch (this.tab) {
          case 0:
            await this.getExpertPharmacies();
            break;
          case 1:
            await this.getExpertOffers();
            break;
        }
      } else if (this.userType == "pharmacygroup") {
        switch (this.tab) {
          case 0:
            await this.getPharmacyGroupOffers({
              offer_status: "selection",
              pharmacy_id: this.childId
            });

            break;
          case 1:
            await this.getPharmacyGroupOffers({
              offer_status: "closed",
              pharmacy_id: this.childId
            });
            break;
          case 2:
            await this.getPharmacyGroupOffers({
              offer_status: "draft",
              pharmacy_id: this.childId
            });
            break;
        }
      }
      // FIXME: remove following else if
      // temp solution for seeing all alquimia offers
      else if (this.userType == "alquimia") {
        switch (this.tab) {
          case 0:
            await this.getAlquimiaOffers({
              offer_status: "selection",
              pharmacy_id: this.pharmacyId
            });

            break;
          case 1:
            await this.getAlquimiaOffers({
              offer_status: "closed",
              pharmacy_id: this.pharmacyId
            });
            break;
          case 2:
            await this.getAlquimiaOffers({
              offer_status: "draft",
              pharmacy_id: this.pharmacyId
            });
            break;
        }
      }
      this.disableLoading();
    }
  },
  computed: {
    ...mapGetters({ pharmacyOffers: "offer/offers" }),
    ...mapGetters({ pharmacies: "pharmacy/pharmacies" }),
    ...mapGetters({ candidateOffers: "match/offers" }),
    ...mapGetters({ userType: "user/type" }),
    ...mapGetters({ language: "language/language" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ groupId: "user/groupId" }),
    ...mapGetters({ ShowOfferExplain: "user/ShowOfferExplain" }),
    offers() {
      if (this.userType == "candidate") return this.candidateOffers;
      else return this.pharmacyOffers;
    }
  },
  mounted() {
    // this.getDefaultOffers();
    if (["pharmacygroup", "alquimia"].includes(this.userType)) {
      this.pharmacyId = this.childId;
      this.getPharmacies();
    }
    if (this.$route.query.payModal) {
      this.payModal = true;
      this.serviceType = this.$route.query.payModal;
      this.$router.push("/offers");
    }
    if (this.$route.params.initialModal) {
      this.initialModal = true;
      this.intialType = this.$route.params.initialModal;
    }
    this.getCandidateStatusChoices();
    this.geStatusChoices(this.userType);
    this.combobox = this.filters[0];
    if (["pharmacy", "alquimia", "pharmacygroup"].includes(this.userType)) {
      this.items = tabsOptions[0].pharmacy;
    } else if (this.userType == "candidate") {
      this.items = tabsOptions[0].candidate;
    } else if (this.userType == "expert") {
      this.items = tabsOptions[0].expert;
    } else if (this.userType == "alquimia") {
      this.items = tabsOptions[0].pharmacy;
    }
    setTimeout(() => {
      this.mounted = true;
    }, 900);
  },

  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ getPharmacyOffers: "offer/getOffers" }),
    ...mapActions({ getPharmacies: "pharmacy/getPharmacies" }),
    ...mapActions({ getAlquimiaOffers: "offer/getAlquimiaOffers" }),
    ...mapActions({ getPharmacyGroupOffers: "offer/getPharmacyGroupOffers" }),
    ...mapActions({ updateChildId: "user/updateChildId" }),
    ...mapActions({ getCandidateOffers: "match/getOffers" }),
    ...mapActions({ getExpertOffers: "offer/getExpertOffers" }),
    ...mapActions({ getExpertPharmacies: "expert/getExpertPharmacies" }),
    ...mapActions({
      getCandidateStatusChoices: "core/getCandidateStatusChoices"
    }),
    ...mapActions({
      geStatusChoices: "core/getStatusChoices"
    }),
    redirectNewOffer() {
      this.$router.push({ name: "offers-new" });
    },
    redirectNewPharmacy() {
      this.$router.push({ name: "register-validated" });
    },
    showOfferExplainModal() {
      return (
        this.mounted &&
        this.ShowOfferExplain &&
        this.pharmacyOffers.length == 0 &&
        this.tab == 0 &&
        this.userType === "pharmacy"
      );
    }
  }
};
</script>
<style scoped>
.v-tabs-bar.v-tabs-bar--is-mobile .v-tab {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.v-slider-group {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.theme--light.v-tabs-items {
  background-color: transparent;
}
.shadowTabs {
  background-color: rgba(0, 0, 0, 0.12);
  font-weight: 700;
}
</style>
