<template>
  <v-dialog
    @click:outside="dialog = false"
    v-model="dialog"
    max-width="550"
    transition="dialog-bottom-transition"
  >
    <v-container class="background rounded-lg">
      <v-row cols="12" sm="6" md="4" justify="end">
        <v-icon
          class="ma-0 pa-0 mt-2 mr-2"
          color="primary"
          @click="dialog = false"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row class="align-center ma-0 pa-0 my-2" justify="center">
        <v-img
          alt="ApoTalent"
          class="shrink mr-2"
          src="@/assets/apotalent/Isotipo black.svg"
          transition="scale-transition"
          width="95"
        />
      </v-row>
      <v-row
        class="primary--text text-h5 ma-0 pa-0 ml-4 font-weight-semibold"
        justify="center"
        >{{ keyValues["welcome_title"] }}</v-row
      >
      <v-row class="secondary--text mx-4 mb-8"
        ><span id="field"> </span>
      </v-row>
      <v-row class="mx-2 my-8" justify="center">
        <v-col cols="10" class="pa-0 mt-2 text-center font-weight-medium">
          <div>
            <v-btn
              @click="dialog = false"
              color="secondary"
              class="text-capitalize"
              style="font-size: 16px"
              large
              rounded
            >
              {{ $t("operation.understood") }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    type: {
      required: false,
      type: String
    }
  },
  computed: {
    ...mapGetters({ keyValues: "core/keyValues" })
  },
  methods: {
    ...mapActions({ getKeyValues: "core/getKeyValues" })
  },
  async mounted() {
    await this.getKeyValues();
  },
  watch: {
    keyValues: {
      handler() {
        if (this.type == "CANDIDATE") {
          document.getElementById("field").innerHTML = this.keyValues[
            "welcome_candidate"
          ];
        }
        if (this.type == "PHARMACY") {
          document.getElementById("field").innerHTML = this.keyValues[
            "welcome_pharmacy"
          ];
        }
      }
    }
  },

  data() {
    return {
      dialog: true
    };
  }
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
.customButton {
  box-shadow: none;
  text-transform: none;
  font-size: 16px;
}
</style>
